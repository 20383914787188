import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import { useState, useEffect, useRef } from "react";
import { route } from "../../../../../../js/helpers";
import { format } from "../../../../../../js/lib/Date";
import { uctrans } from "../../../../../../js/lib/Translator";
import AjaxForm from "../../../../../../js/react/components/general/AjaxForm";
import Select from "../../../../../../js/react/components/general/Select";
import Spinner from "../../../../../../js/react/components/general/Spinner";
import SubmitBar from "../../../../../../js/react/components/general/SubmitBar";
import useSelectHandler from "../../../../../../js/react/hooks/useSelectHandler";
import FormattedNumberText from "../FormattedNumberText";

export default function ClientTreatmentReport() {
	const [formValues, setFormValues] = useState(window.data.formValues ? cloneDeep(window.data.formValues) : []);
	const [loading, setLoading] = useState(false);

	const formRef = useRef(null);
	const [result, setResult] = useState(null);
	const formInfo = window.data.formInfo ? cloneDeep(window.data.formInfo) : [];

	const selectHandler = useSelectHandler(setFormValues);

	const onSuccess = response => {
		if (response.data) {
			setLoading(false);
			setResult(response.data);
		}
	};

	const onError = error => {
		console.error(error);
		setLoading(false);
	};

	async function fetchDefaultResult() {
		setLoading(true);
		const response = await axios.post(route(`crm.report.client-treatments.data`, { ...formValues }));
		if (response) {
			setLoading(false);
			setResult(response.data);
		}
	}

	const getTotalRow = (entry, name) => (
		<tr className="totaal">
			<td className="naam">{name}</td>
			<td className="startdatum">.</td>
			<td className="einddatum">.</td>
			<td className="aantal_verdeelbaar">{entry.amount_dividable}</td>
			<td className="aantal_invulling">{entry.count_participants}</td>
			<td className="factuurbedrag">
				{entry.invoice_amount > 0 ? (
					<FormattedNumberText value={entry.invoice_amount} prefix="€ " decimalScale={2} />
				) : (
					"-"
				)}
			</td>
			<td className="kostenperdeelnemer">
				{entry.costs_per_participant > 0 ? (
					<FormattedNumberText value={entry.costs_per_participant} prefix="€ " decimalScale={2} />
				) : (
					"-"
				)}
			</td>
			<td className="kosten_final">
				<FormattedNumberText value={entry.costs_final} prefix="€ " decimalScale={2} />
			</td>
			<td className="kostenperdeelnemer_final">
				<FormattedNumberText value={entry.costs_per_participant_final} prefix="€ " decimalScale={2} />
			</td>
		</tr>
	);

	useEffect(() => {
		fetchDefaultResult();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<AjaxForm
				method="POST"
				submitUrl={route("crm.report.client-treatments.data")}
				onSubmit={() => setLoading(true)}
				onSuccess={onSuccess}
				onError={onError}
				data={{ ...formValues }}
				ref={formRef}
				useFlashMessage>
				<div className="page-title-container">
					<h1 className="page-title">
						{uctrans("report.report")} - {uctrans("report.report_types.client_treatment")}
					</h1>
				</div>
				<div className="w-full flex">
					<div className="w-1/2 border-gray p-3 my-6">
						<div className="flex w-full justify-between">
							<div className="w-1/4 pr-2">
								<label htmlFor="year">{uctrans("report.year")}</label>
								<Select
									name="year"
									value={formInfo.years.filter(({ value }) => `${value}` === `${formValues.year}`)}
									options={formInfo.years}
									isClearable={false}
									onChange={selectHandler}
								/>
							</div>
						</div>
						<div className="flex w-full justify-start">
							<SubmitBar
								translateLabel={false}
								onSubmit={() => setLoading(true)}
								disabled={loading}
								buttonContent={uctrans("report.show_report")}
							/>
						</div>
					</div>
					<div className="w-1/2 border-gray p-3 my-6">
						{!!result && (
							<table className="budget_totaal_table rapportage_table reporttable">
								<thead>
									<tr>
										<th colSpan="2">{`${uctrans("client_treatment.fields.budget_iv")} - ${
											result && result.year ? result.year : ""
										}`}</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Budget</td>
										<td>
											<FormattedNumberText
												prefix="€ "
												value={result && result.sum && result.sum.budget ? result.sum.budget : 0}
												decimalScale={2}
											/>
										</td>
									</tr>
									<tr>
										<td>Kosten</td>
										<td>
											<FormattedNumberText prefix="€ " value={result?.total?.costs_final ?? 0} decimalScale={2} />
										</td>
									</tr>
									<tr>
										<td>Resterend</td>
										<td>
											<FormattedNumberText
												prefix="€ "
												value={result && result.sum && result.sum.remaining ? result.sum.remaining : 0}
												decimalScale={2}
											/>
										</td>
									</tr>
									<tr>
										<td>Verbruikt (%)</td>
										<td>
											<FormattedNumberText
												percentage
												value={result && result.sum && result.sum.percentage_used ? result.sum.percentage_used : 0}
												decimalScale={2}
											/>
										</td>
									</tr>
								</tbody>
							</table>
						)}
					</div>
				</div>
			</AjaxForm>

			<div className="mt-5">
				{!!loading && (
					<div className="flex justify-center">
						<Spinner width={50} />
					</div>
				)}
				{!loading && !!result && (
					<div>
						<table className="budget_ct_table rapportage_table reporttable">
							<thead>
								<tr>
									<th colSpan="9">{`${uctrans("client_treatment.plural")} - ${result.year ? result.year : ""}`}</th>
								</tr>
								<tr className="head1">
									<td colSpan="3" className="clienttreatment">
										{uctrans("client_treatment.singular")}
									</td>
									<td colSpan="2" className="aantallen">
										{uctrans("report.client_treatment.numbers")}
									</td>
									<td colSpan="4" className="kosten">
										{uctrans("report.client_treatment.costs")}
									</td>
								</tr>

								<tr className="head2">
									<td className="naam">{uctrans("report.client_treatment.name")}</td>
									<td className="startdatum">{uctrans("report.client_treatment.start_date")}</td>
									<td className="einddatum">{uctrans("report.client_treatment.end_date")}</td>
									<td className="aantal_verdeelbaar">{uctrans("report.client_treatment.amount_budget")}</td>
									<td className="aantal_invulling">{uctrans("report.client_treatment.amount_divided")}</td>
									<td className="factuurbedrag">{uctrans("report.client_treatment.invoice_amount")}</td>
									<td className="kostenperdeelnemer">{uctrans("report.client_treatment.costs_per_unit")}</td>
									<td className="kosten_final">{uctrans("report.client_treatment.total_costs")}</td>
									<td className="kostenperdeelnemer_final">
										{uctrans("report.client_treatment.costs_per_unit_after_invoice")}
									</td>
								</tr>
							</thead>
							<tbody>
								{result.data.length ? (
									<>
										{map(result.data, entry => (
											<tr>
												<td className="naam">{entry.name ? entry.name : ""}</td>
												<td className="startdatum">{entry.start_date ? format(entry.start_date, "dd-MM-y") : ""}</td>
												<td className="einddatum">{entry.end_date ? format(entry.end_date, "dd-MM-y") : ""}</td>
												<td className="aantal_verdeelbaar">{entry.amount_dividable ? entry.amount_dividable : ""}</td>
												<td className="aantal_invulling">{entry.count_participants ? entry.count_participants : ""}</td>
												<td className="factuurbedrag">
													{entry.invoice_amount > 0 ? (
														<FormattedNumberText value={entry.invoice_amount} prefix="€ " decimalScale={2} />
													) : (
														"-"
													)}
												</td>
												<td className="kostenperdeelnemer">
													{entry.costs_per_participant > 0 ? (
														<FormattedNumberText value={entry.costs_per_participant} prefix="€ " decimalScale={2} />
													) : (
														"-"
													)}
												</td>
												<td className="kosten_final">
													<FormattedNumberText value={entry.costs_final} prefix="€ " decimalScale={2} />
												</td>
												<td className="kostenperdeelnemer_final">
													<FormattedNumberText value={entry.costs_per_participant_final} prefix="€ " decimalScale={2} />
												</td>
											</tr>
										))}
										{!!result.total && getTotalRow(result.total, "Totaal / Gemiddeld")}
									</>
								) : (
									<tr>
										<td colSpan="9">{uctrans("report.no_results")}</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				)}
			</div>
		</>
	);
}
